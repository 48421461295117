<template>
  <div class="map-wrapper">
    <div class="amap-box">
      <div id="amapContainer" class="_map"></div>
      <div class="autocomplete-box"></div>
    </div>
    <div class="options-box">
      <van-dropdown-menu>
        <van-dropdown-item v-model="optionDropdownA" :options="columnsDropdownA" @change="onChangeDropdownItemA" />
        <van-dropdown-item v-model="optionDropdownB" :options="columnsDropdownB" @change="onChangeDropdownItemB" />
        <van-dropdown-item v-model="optionDropdownC" :options="columnsDropdownC" @change="onChangeDropdownItemC" />
      </van-dropdown-menu>
    </div>
    <van-popup v-model="showPickerList" position="bottom" round :overlay="false" :style="{ height: '32%', paddingBottom:'60px' }">
      <div class="locations-picker">
        <div class="_title">当前区域活动商户列表 </div>
        <div class="_list">
          <div class="_item" @click="onClickGoItem(currInfo)" v-if="currInfo">
            <van-row>
              <van-col span="17">
                <div class="_name">{{currInfo.name}}</div>
                <div class="_address">{{currInfo.address}}</div>
              </van-col>
              <van-col span="7" >
                <div class="_btn"><van-button round icon="guide-o" size="mini" color="linear-gradient(to right, #ff6034, #ee0a24)" @click="goToAmap(currInfo)">导航前往</van-button></div>
                <div class="_km">{{currInfo.km}} km</div>
              </van-col>
            </van-row>
          </div>
          <div class="_item" @click="onClickGoItem(item)" v-for="item of locationsData">
            <van-row>
              <van-col span="17">
                <div class="_name">{{item.name}}</div>
                <div class="_address">{{item.address}}</div>
              </van-col>
              <van-col span="7" >
                <div class="_btn"><van-button round icon="guide-o" size="mini" color="linear-gradient(to right, #ff6034, #ee0a24)" @click="goToAmap(item)">导航前往</van-button></div>
                <div class="_km">{{item.km}} km</div>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="_bottom_fixed _bottom_show_all" style="height: 0">
      <div class="_height"></div>
      <div class="_bottom_back" style="z-index: 2004">
        <span class="_back_home" @click="$router.push('/home?type=')"></span>
        <span class="_back_prev" @click="$router.go(-1)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueAMap from '@vuemap/vue-amap';
import { lazyAMapApiLoaderInstance } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
import wx from "weixin-js-sdk";
Vue.use(VueAMap);
export default {
  components: {},
  data() {
    return {
      map: null,
      geocoder: null,
      marker: null,
      locationsData: [],
      showPickerList: true,
      currInfo:null,
      optionDropdownA: null,
      columnsDropdownA: [],

      optionDropdownB: null,
      columnsDropdownB: [
        {
        text: '成都市',
        value: '成都市'
      }, {
        text: '德阳市',
        value: '德阳市'
      }, {
        text: '广安市',
        value: '广安市'
      }, {
        text: '广元市',
        value: '广元市'
      }, {
        text: '巴中市',
        value: '巴中市'
      }, {
        text: '宜宾市',
        value: '宜宾市'
      }, {
        text: '南充市',
        value: '南充市'
      }, {
        text: '凉山彝族自治州',
        value: '凉山彝族自治州'
      }, {
        text: '内江市',
        value: '内江市'
      }, {
        text: '乐山市',
        value: '乐山市'
      }, {
        text: '攀枝花市',
        value: '攀枝花市'
      }, {
        text: '泸州市',
        value: '泸州市'
      }, {
        text: '甘孜藏族自治州',
        value: '甘孜藏族自治州'
      }, {
        text: '绵阳市',
        value: '绵阳市'
      }, {
          text: '眉山市',
          value: '眉山市'
        },
        {
        text: '自贡市',
        value: '自贡市'
      }, {
        text: '资阳市',
        value: '资阳市'
      }, {
          text: '遂宁市',
          value: '遂宁市'
        }, {
        text: '达州市',
        value: '达州市'
      }, {
        text: '阿坝藏族羌族自治州',
        value: '阿坝藏族羌族自治州'
      }, {
        text: '雅安市',
        value: '雅安市'
      }],

      optionDropdownC: null,
      columnsDropdownC: [],

      centerLng:0,
      centerLat:0,
      // store_type:1,
      userLng:104.068411,
      userLat:30.641228
    };
  },
  onload(){
  },
  mounted() {
    this.getStoreType();
    // 获取
    if(this.$route.query.store_type) {
      this.optionDropdownA = Number(this.$route.query.store_type);
    }
    if(this.$route.query.store_city) {
      this.optionDropdownB = this.$route.query.store_city;
    } else {
      this.optionDropdownB = '成都市';
    }
    this.getColumns();
    this.initMapBox();
  },

  methods: {


    async getStoreType(){
      let {data} = await this.$ajax.gateway("/api/api_get_store_type_detail_by_id", {
        "METHOD": "get",
        "id": this.$route.query.store_type
      });
      if(data.is_pre==0){
        this.columnsDropdownA=[{
          value: data.value,
          text: data.text
        } ]
      }else{
        this.columnsDropdownA=[{
          value: data.value,
          text: data.text
        },...data.list]
      }
    },
    initMapBox() {
      VueAMap.initAMapApiLoader({
        key: '87e94072d529613da003241bb248d0ce',
      });
      lazyAMapApiLoaderInstance.then(() => {
        this.map = new AMap.Map('amapContainer', {
          zooms: [12, 18],
          zoom: 15,
          WebGLParams:{
            preserveDrawingBuffer:true
          }
        });
        this.map.on('complete',this.mapisok)
      });
    },

    setMapCenter({longitude, latitude}) {
      // 设置中心点
      this.map.setCenter(new AMap.LngLat(longitude,  latitude));
      // 添加我当前地址图标
      let marker = new AMap.Marker({
        position: new AMap.LngLat(this.userLng,  this.userLat),
        icon: new AMap.Icon({
          imageSize: new AMap.Size(50, 50),
          image: "http://pic.eaworks.cn/scgc/amap/0.png"
        })
      });
      this.map.add(marker);
    },
    mapisok(){
      this.getJsInfo();
    },
    async getJsInfo(){
      let _this = this;
      // 生成网页js签名
      let {data,code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
          // "url": 'http%3A%2F%2Fscb.eaworks.cn%2Fpage%2Fmap%3Fstore_type%3D2'
        }
      });
      //http://pic.eaworks.cn/scgc/amap/0.png
      if(code===200){
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      } else {
        // alert("js签名失败");
        console.warn("js签名失败", data,code)
        this.getColumns();
        return;
      }
      wx.ready(function( ){
        console.log("-> wx.ready")
        wx.getLocation({
          type: 'gcj02', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
          success: res => {
            _this.userLng = res.longitude;
            _this.userLat = res.latitude;
            console.info("gps res================>",res);
            _this.setMapCenter({longitude:res.longitude,latitude:res.latitude});
            _this.getColumns();
            // alert("获取定位成功");
          },
          fail:res=>{
            console.info(res)
            console.warn("-> 获取定位失败", res)
            // alert("获取定位失败");
            // alert(res);
            // alert(JSON.stringify(res));
            _this.getColumns();
          },
          cancel:res=>{
            console.info(res)
            console.warn("-> 不允许获取定位", res)
            // alert("不允许获取定位");
            _this.getColumns();
          },
        });
        // 3. Columns
        // _this.getColumns();
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function(res){
        console.info("wx error",res);
      });
    },

    onChangeDropdownItemA(value) {
      // this.optionDropdownB = '成都市'
      this.$nextTick(() => {
        this.getLocationsData();
      });
    },
    onChangeDropdownItemB(value) {
      this.optionDropdownC = '';
      this.columnsDropdownC = [];
      this.getColumns();
    },
    onChangeDropdownItemC(value) {
      this.$nextTick(() => {
        this.getLocationsData();
      });
    },
    async getColumns() {
      let {data}  = await this.$ajax.gateway("/api/admin_get_area_list", {
        "body": {
          "store_city": this.optionDropdownB
        }
      });
      for(let item of data){
        item.value = item.text;
      }
      this.columnsDropdownC =[{
        value: '全部',
        text: '全部'
      }, ...data];

      this.optionDropdownC = this.columnsDropdownC[0].value;
      this.$nextTick(() => {
        this.getLocationsData();
      })
    },

    async getLocationsData() {
      // 获取指定类型的gps点位
      let {data} = await this.$ajax.gateway("/api/admin_get_store_list", {
        "body": {
          "store_type": this.optionDropdownA,
          "store_area": this.optionDropdownC,
          "store_city":this.optionDropdownB ,
          "lng":this.userLng,
          "lat":this.userLat,
        }
      });
      this.currInfo=null;
      let locations = [];
      for (let i = 0; i < data.length; i++) {
        locations.push({
          id: data[i].id,
          name: data[i].store_name,
          address: data[i].store_address,
          km:data[i].km,
          icon: "http://pic.eaworks.cn/scgc/amap/"+this.optionDropdownA+".png",
          lat:  data[i].temp_gps.split(",")[0],
          lng:  data[i].temp_gps.split(",")[1],
        })
      };
      // console.info(locations);
      console.info("-> get locations", locations);
      this.locationsData = locations;
      this.initAMap(locations);
    },

    initAMap(location) {
      setTimeout(() => {
        this.addMarker(location);
      }, 100)
    },
    addMarker(location) {
      if(!this.map){
        return;
      }
      this.map.clearMap();
      this.setMapCenter({longitude:this.userLng,latitude:this.userLat});
      var layer = new AMap.LabelsLayer({
        zooms: [12, 17],
        zIndex: 1000,
        collision: false
      });
      var markers = [];
      // var icon = {
      //   type: 'image',
      //   icon: "http://pic.eaworks.cn/scgc/amap/"+this.optionDropdownA+".png",
      //   size: [50, 50],
      //   anchor: 'bottom-center',
      // };

      // var iconUrl = "http://pic.eaworks.cn/scgc/amap/"+this.optionDropdownA+".png";
      // var icon = new AMap.Icon({
      //   size: new AMap.Size(50, 50),    // 图标尺寸
      //   image:  require(iconUrl) ,  // Icon的图像
      //   // imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
      //   // imageSize: new AMap.Size(40, 50)   // 根据所设置的大小拉伸或压缩图片
      // });




      for (var i = 0; i < location.length; i++) {
        var curPosition = location[i];
        let marker = new AMap.Marker({
          position: new AMap.LngLat(curPosition.lng,  curPosition.lat),
          icon: new AMap.Icon({
            imageSize: new AMap.Size(80, 80),
            image: "http://pic.eaworks.cn/scgc/amap/"+this.optionDropdownA+".png"
          }),
          extData:curPosition
        });
        marker.on('click', this.markerClick)
        // .on
        this.map.add(marker);

        //
        //
        // var curData = {
        //   position: [curPosition.lng,curPosition.lat],
        //   icon:iconUrl,
        //   extData: location[i]
        // };
        // // console.info("icon",icon);
        // var labelMarker = new AMap.LabelMarker(curData);
        // markers.push(labelMarker);
        // // 给marker绑定事件
        // labelMarker.on('click', this.markerClick)
      }
      // layer.add(markers);
      // this.map.add(layer);
    },

    markerClick(e) {
      // console.info("=============>",e.target._opts.extData);
      // this.map.setCenter();
      this.map.setZoomAndCenter(15,new AMap.LngLat(e.lnglat.lng, e.lnglat.lat));
      // this.map.setZoom(12);
      this.currInfo = e.target._opts.extData;
        // this.goToAmap();
    },

    onClickGoItem(item) {
      this.map.setZoomAndCenter(14,new AMap.LngLat(item.lng, item.lat));
      // this.map.setCenter(new AMap.LngLat(item.lng, item.lat));
    },

    goToAmap(item){
      let url  = "http://uri.amap.com/navigation?&to="+item.lng+","+item.lat+","+item.address+"&callnative=1";
        window.location.href=url;
    },
  }
};
</script>

<style lang="less" scoped>
.amap-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  ._map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.options-box {
  width: 96%;
  height: 1.2rem;
  position: fixed;
  left: 2%;
  top: 0.4rem;
  z-index: 2;
  background: #fff;
  border-radius: 0.2rem;
  font-size: 0.4rem;
  text-align: center;
  box-shadow: 0 6px 12px 0 rgba(30, 38, 51, 0.12);
  z-index: 3000;
  span {
    display: block;
    width: 100%;
    margin-top: 0.3rem;
    line-height: 0.6rem;
    &._tw {
      border-left: 0.01rem solid #ddd;
      border-right: 0.01rem solid #ddd;
    }
  }
  .van-icon {
    transform: rotate(90deg)
  }
}
.locations-picker {
  padding: 0.3rem;
  ._title {
    font-size: 0.38rem;
    color: #333;
    line-height: 0.5rem;
    padding-bottom: 0.2rem;
  }
  ._list {
    ._item {
      padding: 0.2rem 0;
      border-top: 0.01rem solid #ddd;
      ._name {
        font-size: 0.38rem;
        padding: 0.1rem 0;
      }
      ._address {
        color: #888;
        font-size: 0.28rem;
      }
      ._btn {
        text-align: right;
        position: relative;
        top: -0.45rem;
      }
      ._km {
        font-size: 0.35rem;
        color: #ee0a24;
        text-align: right;
        position: relative;
        top: -0.35rem;
      }
    }
  }

}
</style>
